import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

import imageZweitmeinung from '../../images/Operationen-Zweitmeinung (unsplash)-min.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Therapien | Orthopädie Dr. Schubert" description="Orthopädische Therapien ✓ Eigenbluttherapie ✓ Kinesiotaping ✓ Dry Needling ✓ Zweitmeinung ✓ Termine online vereinbaren ✓" />
    <div className="greenContainer">
      <ul>
        <li><Link to="/therapien/klassisch">Klassisch</Link></li>
        <li className="active"><Link to="/therapien/innovativ">Innovativ</Link></li>
        <li><Link to="/therapien/integrativ">Integrativ</Link></li>
      </ul>
    </div>
    <Section dark>
      <Container>
        <Headline h1 light subtitle="Klassisch / Innovativ / Integrativ">Therapien</Headline>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                ACP/PRP/Biologics (Eigenbluttherapie) -
                {' '}
                <i>die Kraft des eigenen Blutes</i>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                ACP steht für
                {' '}
                <b>a</b>
                utologes
                {' '}
                <b>c</b>
                onditioniertes
                {' '}
                <b>P</b>
                lasma und PRP für
                {' '}
                <b>p</b>
                lättchen
                <b>r</b>
                eiches
                {' '}
                <b>P</b>
                lasma.
                <br />
                <br />
                Diese neuartigen biologischen Behandlungsverfahren nutzen einen Bestandteil ihres Blutes, das Plasma, als Heilmittel - ohne Zusatz. Allergien oder Unverträglichkeiten sind dadurch ausgeschlossen, ein Vorteil gegenüber allen anderen Therapien.
                <br />
                <br />
                Hierfür wird Ihnen 15 ml Blut aus der Armvene entnommen und durch eine Zentrifuge aufgearbeitet und getrennt.
                Das daraus gewonnene Blutplasma ist reich an Blutplättchen (Thrombozyten) und verschiedenen Wachstumsfaktoren.
                <br />
                <br />
                Durch das spezielle Doppelkammersystem der Spritze ist eine sterile Blutabnahme und anschließende Injektion der Wachstumsfaktoren in das zu behandelnde Gebiet mit höchstmöglicher Sicherheit gewährleistet.
                <br />
                <br />
                Diese wirken - ähnlich wie bei der Wundheilung - geweberegenerativ. Sie stimulieren den Körper, an der erkrankten Stelle neue Zellen (Blutgefässe/ Hyaluronsäure/ Kollagen) entstehen zu lassen. Gleichfalls kommt es zu einer entzündungshemmenden Wirkung.
                <br />
                <br />
                Häufigste Anwendung:
                <ul>
                  <li>Arthrose (Knie/ Hüfte/ Wirbelsäule)</li>
                  <li>Sehnenansatzreizungen („Tennisellenbogen“/ Fersensporn/ Achillessehne)</li>
                  <li>Sehnenverletzungen und Muskelfaserrisse</li>
                </ul>

              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Laser  -
                {' '}
                <i>Heilen mit  Licht</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Durch Licht werden viele lebenswichtige Funktionen wie Zellstoffwechsel, Immunsystem und Durchblutung in unserem Körper angeregt.
                <br />
                <br />
                Die Lasertherapie ist eine Behandlung mit gebündeltem, energiereichem Licht.
                <br />
                <br />
                Je nach Wellenlänge und Leistung kann eine mehr schmerzlindernde oder biostimulierende Wirkung erzeugt werden.
                <br />
                <br />
                Die moderne Lasertherapie ist eine sanfte und schmerzfreie Behandlungsmethode ohne Nebenwirkungen.
                <br />
                <br />
                Anwendung:
                <ul>
                  <li>bei chronischen und akuten Schmerzen</li>
                  <li>Sportverletzungen</li>
                  <li>Sehnenentzündungen</li>
                  <li>Überlastungsschäden (z.B. „Tennis-Arm“/„Runners knee“)</li>
                  <li>Arthrose</li>
                </ul>
                <br />
                <br />
                Die Behandlung umfasst in der Regel 6-10 Therapieeinheiten von jeweils 15 bis 20 Minuten.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Kinesiotaping -
                {' '}
                <i>Klebestreifen für Muskeln und Gelenke</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Beim Kinesiotape handelt es sich um ein elastisches Baumwollband mit einer Acrylklebeschicht. Das Tape wird unter Zug und Dehnung angebracht und entfaltet in Abhängigkeit der Klebetechnik muskelentspannende, -stärkende oder abschwellende Wirkung.
                <br />
                <br />
                Wir setzen es insbesondere bei Verletzungen und Störungen von Gelenken und Muskeln ein.
                <br />
                <br />
                Das Tape ist alltagstauglich. Sie können sich uneingeschränkt bewegen, es löst sich nicht beim Duschen und hält 4-7 Tage auf der Haut.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Triggerpunktbehandlung / Dry Needling -
                {' '}
                <i>punktgenau gegen den Schmerz</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Als Triggerpunkt werden kleine, druckschmerzhafte Muskelverhärtungen bezeichnet. Diese Druckpunkte spiegeln Fehlfunktionen, die an einer anderen Körperstelle entstanden sind, wider.
                <br />
                <br />
                Ziel der Behandlung ist es, diesen Triggerpunkt aufzufinden und zu behandeln. Zur Therapie können radiale oder fokussierte Stosswellen sowie lokale Injektionen mit einem Betäubungsmittel eingesetzt werden.
                <br />
                <br />
                Eine spezielle Therapiemöglichkeit ist das Dry Needling, eine Akupunkturtechnik.
                <br />
                <br />
                Dr. Schubert ist speziell geschulter Trigger-Therapeut, Mitglied der Fachgesellschaft und aufgeführt in der deutschlandweiten Behandlerliste.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Zweitmeinung / Operation -
                {' '}
                <i>ja oder nein?</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Row>
                <Col xs={12} sm={6}>
                  <p>
                    In meiner über 20 jährigen orthopädischen Tätigkeit an Universitätskliniken und in einer renommierten, operativ ausgerichteten Praxisklinik in Gemeinschaft mit Dr. Rosenthal war der immer entscheidende Punkt abzuwägen, was eine Operation verbessern oder erzielen kann und wo deren Grenzen zu sehen ist.
                    <br />
                    Mit dem Wissen und der Erfahrung über mehr als 15.000 operativer Behandlungsverläufe kann ich Ihnen kompetent und unbefangen beratend zur Seite stehen.
                    <br />
                    <br />
                    Scheuen Sie sich nicht, einen Termin zu vereinbaren.
                  </p>
                </Col>
                <Col xs={12} sm={6}>
                  <img src={imageZweitmeinung} alt="Zweitmeinung" />
                </Col>

              </Row>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
